.invoice {
  max-width: 800px;
  margin: 40px auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: white;
  color: black;
  font-weight: normal;
}

.pad {
  padding: 0 40px;
}

.brand {
  margin-bottom: 40px;
  display: flex;
  margin-top: 30px;
}

.company-address {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.brand .logo {
  display: inline-block;
  border-radius: 4px;
}

.addresses {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  text-align: left;
}

.address {
  display: block;
}

.addresses .from,
.addresses .to {
  padding: 20px;
  border-radius: 4px;
  width: 100%;
  max-width: 266px;
}

.addresses .from {
  display: block;
  font-style: normal;
  margin-right: 10px;
  background: #efefef;
  border: 1px solid #ccc;
}

.addresses .to {
  display: block;
  margin-left: 10px;
  padding: 0;
}

.addresses .to .value {
  text-align: right;
}

.valueTable {
  margin: 0 0 0 auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  min-width: 266px;
  max-width: 300px;
}

.valueTable > .row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-bottom: 1px solid #ccc;
}

.valueTable > .row:last-of-type {
  border-bottom: none;
}

.valueTable > .row > div {
  padding: 10px;
}

.valueTable > .row > .label {
  display: flex;
  /*justify-content: center;*/
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
}

.totalContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 0 40px;
}

.pay {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0 10px 10px;
}

button.payNow {
  display: block;
  padding: 10px 20px;
  background: lightyellow;
  border-radius: 4px;
  border: 1px solid #444;
}

.footer {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
}

.footer .comments {
  padding: 10px;
  border-radius: 4px;
}

.footer .comments h4,
.footer .comments h5 {
  margin-top: 0;
  margin-bottom: 5px;
}

.comments {
  text-align: left;
  background: #eee;
}
.closing {
  padding-top: 10px;
}

.title {
  font-weight: bold;
  font-size: 20px;
  margin-top: 2rem;
  display: flex;
  margin-bottom: 1rem;
}
