@tailwind base;
@tailwind components;
@tailwind utilities;

.ReactModal__Body--open .ReactModal__Overlay {
  opacity: 1;
  backdrop-filter: blur(8px) !important;
  background-color: rgba(103, 122, 98, 0.7) !important;
}

* {
  text-align: start;
}