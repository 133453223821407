.main {
  text-align: center;
  padding: 0px 0;
  background: #ebf0f5;
  display: flex;
  height: 100vh;
  align-items: center;
}
h1 {
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}

h1.success {
  color: green;
}

h1.error {
  color: red;
}

p {
  color: #404f5e;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  margin: 0;
}

.card {
  background: white;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #c8d0d8;
  display: inline-block;
  margin: 0 auto;
  max-height: 70vh;
}

.icon-wrapper {
  border-radius: 100%;
  height: 200px;
  width: 200px;
  margin: 0 auto;
}

.icon-wrapper.success {
  background: #cefad0;
}

.icon-wrapper.error {
  background: #ffefea;
}
